import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { RichText as PrismicRichText } from "prismic-reactjs";

import { linkResolver, serializer } from "src/utils";

import { PageHeaderV2, RibbonCTA, SEO } from "src/components";

import Society from "./_components/_society";
import Spaces from "./_components/_spaces";
import Board from "./_components/_board";

const AboutPage = ({ setInvert, setInvertLogo, preview }) => (
  <StaticQuery
    query={graphql`
      {
        about: prismicAbout {
          dataString
        }
      }
    `}
    render={(data) => {
      let page = JSON.parse(data.about.dataString);
      if (preview) {
        page = preview;
      }
      return (
        <>
          <SEO
            image={
              page.opengraph_image &&
              page.opengraph_image.url &&
              page.opengraph_image.url
            }
            imageAlt={
              page.opengraph_image &&
              page.opengraph_image.alt &&
              page.opengraph_image.alt
            }
            title={
              page.opengraph_title &&
              PrismicRichText.asText(page.opengraph_title)
            }
            description={
              page.opengraph_description &&
              PrismicRichText.asText(page.opengraph_description)
            }
          />
          {/* ---------- PAGE  HEADER ---------- */}
          <PageHeaderV2
            heading={`About`}
            image={page.intro_image}
            links={[
              page.society_title &&
                page.society_title[0] &&
                page.society_title[0].text,
              page.spaces_header &&
                page.spaces_header[0] &&
                page.spaces_header[0].text,
              page.board_title &&
                page.board_title[0] &&
                page.board_title[0].text,
            ]}
            color="brand.altPrimary"
            invert={true}
            setInvertLogo={setInvertLogo}
            setInvert={setInvert}
          />
          {/* ---------- SOCIETY INFORMATION ---------- */}
          <Society
            id={
              page.society_title &&
              page.society_title[0] &&
              page.society_title[0].text.replace(/\s/g, "-").toLowerCase()
            }
            title={
              page.society_title &&
              page.society_title[0] &&
              page.society_title[0].text
            }
            description={
              page.society_description &&
              page.society_description[0] &&
              PrismicRichText.render(
                page.society_description,
                linkResolver,
                serializer
              )
            }
            annualReportButtonText={page.annual_report_button_text}
            annualReportLink={
              page.annual_report_link &&
              page.annual_report_link.url &&
              page.annual_report_link.url
            }
            pillarsTitle={
              page.pillars_title &&
              page.pillars_title[0] &&
              page.pillars_title[0].text
            }
            pillars={page.strategic_pillars}
          />
          {/* ---------- SPACES INFORMATION ---------- */}
          <Spaces
            title={
              page.spaces_header &&
              page.spaces_header[0] &&
              page.spaces_header[0].text
            }
            description={
              page.spaces_description &&
              page.spaces_description[0] &&
              PrismicRichText.render(
                page.spaces_description,
                linkResolver,
                serializer
              )
            }
            button1Text={page.spaces_policy_button_text}
            button1Link={page.spaces_policy_button_link}
            button2Text={page.spaces_disclosure_button_text}
            button2Link={page.spaces_disclosure_button_link}
            finePrint={
              page.spaces_fine_print &&
              page.spaces_fine_print[0] &&
              PrismicRichText.render(
                page.spaces_fine_print,
                linkResolver,
                serializer
              )
            }
          />
          {/* ---------- BOARD INFORMATION ---------- */}
          <Board
            id={
              page.board_title &&
              page.board_title[0] &&
              page.board_title[0].text.replace(/\s/g, "-").toLowerCase()
            }
            description={
              page.society_secondary_description &&
              page.society_secondary_description[0] &&
              PrismicRichText.render(
                page.society_secondary_description,
                linkResolver,
                serializer
              )
            }
            image={page.board_image && page.board_image.url && page.board_image}
            title={page.board_title && PrismicRichText.asText(page.board_title)}
            board={page.board}
            boardCaption={page.board_image_caption}
          />
          {page.closing_cta_header &&
            page.closing_cta_header[0] &&
            page.closing_cta_header[0].text && (
              <RibbonCTA
                heading={
                  page.closing_cta_header &&
                  PrismicRichText.asText(page.closing_cta_header)
                }
                body={page.closing_cta_subtext}
                buttonText={page.closing_cta_button_text}
                buttonLink={page.closing_cta_button_link}
                color="brand.altPrimary"
                invert={true}
              />
            )}
        </>
      );
    }}
  />
);

export default AboutPage;
