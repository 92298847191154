import React from "react";
import { mdiDownload } from "@mdi/js";

import {
  Button,
  FlexGrid,
  FlexCol,
  H2,
  H4,
  H6,
  PrismicLink,
  RichText,
  SectionWrapper,
} from "src/components";

const Society = ({
  id,
  title,
  description,
  annualReportLink,
  annualReportButtonText,
  pillarsTitle,
  pillars,
}) => (
  <>
    {(title || description) && (
      <SectionWrapper id={id} py={[8, 9, 10]}>
        <FlexGrid gutterX={[0, 0, 8]}>
          <FlexCol width={["100%", "100%", 1 / 2]}>
            <FlexCol py={[5, 6]}>
              {title && <H2 children={title} />}
              {description && (
                <RichText
                  css={`
                    margin-top: ${(props) => props.theme.space[5] + "px"};
                  `}
                >
                  {description}
                </RichText>
              )}
              {annualReportLink && annualReportButtonText && (
                <div
                  css={`
                    margin-top: ${(props) => props.theme.space[5] + "px"};
                  `}
                >
                  <Button
                    children={annualReportButtonText}
                    as={PrismicLink}
                    link={annualReportLink}
                    iconLeft={mdiDownload}
                    variant="defaultAlt"
                  />
                </div>
              )}
            </FlexCol>
          </FlexCol>
          <FlexCol width={["100%", "100%", 1 / 2]}>
            <FlexCol
              py={[8, 9]}
              px={[7, 8]}
              bg="bg.reverse"
              height="100%"
              justifyContent="center"
            >
              {pillarsTitle && (
                <H6 children={pillarsTitle} color="text.reverseBody" />
              )}
              {pillars &&
                pillars.map(({ pillar }, index) => (
                  <H4
                    children={pillar}
                    key={"pillar" + index}
                    color="text.reverse"
                    mt={[4, 5]}
                  />
                ))}
            </FlexCol>
          </FlexCol>
        </FlexGrid>
      </SectionWrapper>
    )}
  </>
);

export default Society;
