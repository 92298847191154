import React from "react";

import {
  Box,
  H6,
  PrismicImage,
  RichText,
  SectionWrapper,
  Text,
} from "src/components";

const BoardMember = ({ name }) => (
  <div>
    <Text children={name} fontSize={[2, 3]} textAlign="center" />
  </div>
);

const Board = ({ id, description, image, title, board, boardCaption }) => (
  <>
    {image && (
      <Box
        as="section"
        pt={[4, 5, 6]}
        css={`
          position: relative;
          z-index: 0;
          padding: 0 ${(props) => props.theme.margin};
        `}
      >
        <div
          css={`
            width: 100%;
            max-width: ${(props) => props.theme.maxWidth[1]};
            margin: 0 auto;
          `}
        >
          <PrismicImage image={image} sizes="50vw" />
          {boardCaption && (
            <Text
              children={boardCaption}
              fontSize={0}
              mt={[2, 3]}
              textAlign="center"
              color="text.body"
            />
          )}
        </div>
      </Box>
    )}
    {title && board && (
      <SectionWrapper id={id} maxWidth={1} pb={[8, 9, 10]} bg="bg.default">
        {title && (
          <H6 children={title} mb={[5, 6]} mt={[6, 7]} textAlign="center" />
        )}
        {board && (
          <div
            css={`
              margin-top: ${(props) => props.theme.space[5] + "px"};
            `}
          >
            <div
              css={`
                display: flex;
                flex-wrap: wrap;
                margin: -16px;
                > * {
                  width: 33%;
                  padding: 16px;
                }
              `}
            >
              {board.map((item, index) => (
                <BoardMember name={item.name} key={"boardMember" + index} />
              ))}
            </div>
          </div>
        )}
        <div
          css={`
            width: 100%;
            max-width: ${(props) => props.theme.maxWidth[0]};
            margin: 0 auto;
          `}
        >
          {description && (
            <RichText
              textAlign="center"
              fontSize={[1, 2]}
              color="text.body"
              mt={[8, 9, 10]}
            >
              {description}
            </RichText>
          )}
        </div>
      </SectionWrapper>
    )}
  </>
);

export default Board;
