import React from "react";

import {
  Box,
  Button,
  FlexCol,
  H2,
  PrismicLink,
  RichText,
  SectionWrapper,
} from "src/components";

const Spaces = ({
  description,
  title,
  button1Text,
  button1Link,
  button2Text,
  button2Link,
  finePrint,
}) => (
  <>
    {(description || title) && (
      <>
        <SectionWrapper
          pt={[4, 5, 6]}
          pb={[8, 9, 10]}
          maxWidth={0}
          bg="bg.wash"
          id="safer-spaces"
        >
          {title && (
            <H2 textAlign="center" mb={[4, 5]}>
              {title}
            </H2>
          )}
          {description && (
            <RichText
              textAlign="center"
              fontSize={[1, 2]}
              color="text.body"
              mb={[4, 5]}
            >
              {description}
            </RichText>
          )}
          {(button1Text || button2Text) && (
            <FlexCol
              alignItems="center"
              justifyContent="center"
              flexDirection={["column", "row"]}
            >
              {button1Text && (
                <Box mr={[0, 1]}>
                  <Button
                    children={button1Text}
                    as={PrismicLink}
                    link={button1Link}
                    variant="primary"
                  />
                </Box>
              )}
              {button2Text && (
                <Box mt={[1, 0]}>
                  <Button
                    children={button2Text}
                    as={PrismicLink}
                    link={button2Link}
                    variant="default"
                  />
                </Box>
              )}
            </FlexCol>
          )}
          {finePrint && (
            <RichText
              textAlign="center"
              fontSize={[0]}
              color="text.body"
              mt={[4, 5]}
            >
              {finePrint}
            </RichText>
          )}
        </SectionWrapper>
      </>
    )}
  </>
);

export default Spaces;
